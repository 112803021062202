#skills{
    height: 100vh;
    background-color: #1f242d;
    align-content: center;

    .tituloskills{
        color: white;
        font-family: Poppins;
        font-size: 35px;
        text-align: center;
        margin-bottom: 0.5rem;
    }

    span{
        color: aquamarine;
        font-size: 40px;
    }

    .center-about{
        padding: 0rem 0.3rem;

        .about-containers{
            display: grid;
            grid-template-columns:repeat(auto-fill, minmax(300px, 1fr));
            gap: 10px;
            place-content: center;
    
    
            .details-containers{
                width: auto;
                border-radius: 2rem;
                border: 2px solid  #323946;
                text-align: center;
    
                .habilidades-sub-titulo{
                    color: white;
                    font-size: 27px;
                }
        
                .article-container{
                    display: grid;
                    grid-template-columns:repeat(auto-fill, minmax(109px, 1fr));
                    place-items: center;
                    padding: 0.5rem;
                
                    article{
                        display: grid;
                        grid-template-columns:1fr 1fr;
                        place-items: center;
                        font-family: Poppins;
    
                        .icon{
                            width: 45px;
                        }
        
                        h3{
                            color: white;
                            font-size: 13px !important;
                            }
                            
                        p{
                            color: white;
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
    
}