.contact{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    background-color: #1f242d;

    h1{
        color: white;
        font-family: Poppins;
        margin-bottom: 3rem;
        font-size: 35px;
    }

    span{
        color: aquamarine;
        font-size: 40px;
    }

    .contact-box{
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row;
        width: 700px;
        height: 120px;
        border: 1px solid #323946;
        border-radius: 50px;

        div{
            margin: 1rem;

            a{
                text-decoration: none;
                font-size: 35px;
                font-family: Poppins;
                color: white;
                margin-left: .5rem;
            }

            a:hover{
                color: aquamarine;
                border-bottom: 1px solid aquamarine;
                transition: 0.5s ease;
            }
        }
    }
}

.foot{
    height: 12vh;
    display: flex;
    align-items: center;
    justify-content:space-between;
    background-color: #323946;

    p{
        color: white;
        font-family: Poppins;
        font-size: 13px;
        margin: 2rem;
    }

    a{
        border: 1px solid #1f242d;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2rem;
    }

    a:hover{
        background-color: #1f242d;
        transition: 0.5s ease;
    }
}

@media screen and (max-width: 600px){
    .contact{
        padding: 2rem;
        .contact-box{
            width: 100%; 
            height: 40%;
            flex-direction: column;
    
            div{
                margin: 0;
    
                a{
                    margin: 1rem;
                }
            }
        }
    }
}

