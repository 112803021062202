.navbar {
    height: 12vh;
    background-color: #1f242d;
    font-family: 'Poppins';
    border-bottom: 1px solid rgba(127, 255, 212, 0.479);
  
    .navbar-brand {
      margin-left: 70px;
      color: white;
      font-size: 30px;
      }
    }
  
    .navbar-collapse{
        flex-grow: 0;

      .nav-item {
        margin: 1rem;

        .active{
            color: white;
            font-size: 20px;
        }
  
        a:hover {
          color: aquamarine;
          cursor: pointer;
        }
      }
    }

.home{
    height: 100vh;
    background-color: #1f242d;
    padding: 9rem;
    display: flex;
    justify-content: center;
    align-items: center;

    h1, p {
        color: white;
        font-family: Poppins;
        margin: 1rem;
    }

    span{
        color: aquamarine;
        font-size: 30px;
    }

    h1{
        font-size: 39px;
    }
    


    .social-media a{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 4rem;
        border: 2px solid white;
        border-radius: 50%;
    }

    .social-media a:hover{
        background: black;
        transition: .7s ease;
    }


    .btn{
        border: 2px solid white;
        color: white;
        margin-top:15px;
        width: 200px;
    }

    .btn:hover{
        border: solid 1px aquamarine;
        color:aquamarine;
    }

    .imgHome{
        width: 500px;
    }
}
  
@media screen and (max-width: 600px){

    .home{
        flex-direction: column-reverse;
        padding: 2rem !important;

        h1, span{
            font-size: 20px !important;
        }
        
        p{
            font-size: 15px;
        }

        .imgHome{
            width: 300px !important;
        }

        .social-media a{
            width: 3rem;
            height: 3rem;
            margin-right: 1rem;
            margin-left: 1rem;
        }

        .btn{
            margin-left: 1rem;
        }

    }

    .navbar-brand{
        margin-left: 10px !important;
        font-size: 23px !important;
    }

    .navbar-collapse{
        background-color: #323946;
        border: 1px solid aquamarine;

        ul{
            text-align: center !important;
        }

        a:hover{
            border: 1px solid #1f242d !important;
        }
    }

    .navbar-toggler{
        margin-right: 1rem;
        border: 1px solid black;
        .navbar-toggler-icon{
            filter: invert(100%);
        }
    }


}

@media screen and (max-width: 768px){

    section{

        h1, span{
            font-size: 21px;
        }

        p{
            font-size: 13px;
        }

        .social-media a{
            width: 3.5rem;
            height: 3.5rem;
            margin-right: 1rem;
            margin-left: 1rem;
        }

        .imgHome{
            width: 400px;
        }

        .btn{
            margin-left: 1rem;
        }

    }



    .navbar-collapse{
        background-color: #323946;
        border: 1px solid aquamarine;

        ul{
            text-align: center ;
        }

        a:hover{
            border: 1px solid #1f242d ;
        }
    }

    .navbar-toggler{
        margin-right: 1rem;
        border: 1px solid black;
        .navbar-toggler-icon{
            filter: invert(100%);
        }
    }



}

@media screen and (min-width: 1024px){
    section{

        h1, span{
            font-size: 23px;
        }

        p{
            font-size: 15px;
        }

        .social-media a{
            width: 3.5rem;
            height: 3.5rem;
            margin-right: 1rem;
            margin-left: 1rem;
        }

        .imgHome{
            width: 400px;
        }

        .btn{
            margin-left: 1rem;
        }

    }
}