.portafolio{
    height: 100vh;
    background-color:  #323946;
    display: flex;
    justify-content: center;
    flex-direction: column;

    h2{
        color: white;
        margin-bottom: 0.5rem;
        font-size: 35px;
        text-align: center;
        font-family: Poppins;
    }

    span{
        color: aquamarine;
        font-size: 40px;
    }

    .portafolio-container{
        display: grid;
        grid-template-columns:repeat(auto-fill, minmax(350px, 1fr));
        gap: 10px;
        place-content: center;
        padding: 1rem;

        .portafolio-box{
            position: relative;
            border-radius: 2rem;
            box-shadow: 0 0 1rem aquamarine;
            overflow: hidden;
            display: grid;
            place-content: center;
            height: auto;

            .view{
                width: 100%;
                transition: .5s ease;
            }

            .view:hover{
                transform: scale(1.1);
            }

            .portafolio-layer{
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(rgba(0, 0, 0, .1), black);
                display: flex;
                align-items: center;
                flex-direction: column;
                text-align: center;
                justify-content: center;



                h4{
                    color: white;
                    font-family: Poppins;
                }

                .hi{
                    display:inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 4rem;
                    height: 4rem;
                    background-color: rgb(255, 255, 255);
                    border-radius: 50%;
                    margin-top: 2rem;
                    margin: 1rem;
                }

                .hi:hover{
                    transform: scale(1.1);
                    transition: .5s ease;
                }

                .ho{
                    display: flex;
                    flex-direction: row;
                }

            }

            .portafolio-layer:hover{
                transform: scale(1.1);
                transition: .5s ease;
            }


        }
        
    }
}