.about{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 9rem;
    background-color: #323946;
    height: 100vh;
    
    .heading{
        font-size: 40px;
        color: white;
    }

    img{
        border: 2px solid #1f242d;
        border-radius: 50%;
    }

    .about-content{
        color: white;
        font-family: Poppins;

        span{
            color: aquamarine;
            font-size: 40px;
        }

        .btn{
            border: 2px solid white;
            color: white;
            width: 200px;
        }

        .btn:hover{
            border: solid 1px aquamarine;
            color:aquamarine;
        }

        h3{
            color: black;
            font-size: 30px;
        }

        p{
            font-size: 19px;
            margin: 2rem;
        }

        a{
            margin-left: 2rem;
        }
    }

    .about-content h2,h3{
        margin-left: 2rem;
    }

}

@media screen and (max-width: 600px){
    .about{
        flex-direction: column;
        padding: 2rem;

        h3, span, h2{
            font-size: 20px !important;
        }

        p{
            font-size: 13px !important;
            margin: 1rem !important;
        }

        img{
            width: 170px !important;
        }
    }

}

@media screen and (max-width: 768px){
    .about{

        h3{
            font-size: 16px;
        }

        p{
            font-size: 11px !important;
            margin: 0.5 !important;
        }

        img{
            width: 300px ;
        }
        
    }
}

@media screen and (min-width: 1024px){
    .about{

        h2{
            margin-bottom: 1rem !important;
        }

        h3{
            font-size: 18px;
        }

        p{
            font-size: 13px !important;
            margin: 0.5 !important;
        }

        img{
            width: 350px ;
        }
        
    }
}
